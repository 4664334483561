import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Select, Table, Tag, message } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { debounce } from 'lodash';
import { AdminOrders, OrderDetail } from '../../schema/order';
import {
  PLPrintedStatus,
  packedStatus,
  shipLabel,
  shippedStatus,
  invoiceStatus,
  paymentStatus,
  shippingYear
} from '../../common/orderFilters';
import { orderList, getAllProductLines, bulkReminder, sendAcknowledgement, emailInvoice, orderDetails,status } from '../../config/api-routes';
import api from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import { HistoryOutlined } from '@ant-design/icons';
import PickList from './orderDetails/pickList';
import ReactDOMServer from 'react-dom/server';

const { Search } = Input;

function OrderTable() {
  const [productTypes, setProductTypes] = useState([]);
  const [order, setOrderData] = useState<AdminOrders[]>([]);
  const [loading, setLoading] = useState(false);
  const [shippingWeeks, setShippingWeeks] = useState([]);
  const [visible, setVisible] = useState<boolean>();
  const [form] = Form.useForm();
  const [selectedShipWeek, setSelectedShipWeek] = useState<number>(0);
  const [selectedProductType, setSelectedProductType] = useState<string>('');
  const [selectedPayment, setSelectedPayment] = useState<number>(0);
  const [selectedShipped, setSelectedShipped] = useState<number>(0);
  const [loader, setLoaer] = useState<boolean>();
  const [enableBulkEmail, setEnableBulkEmail] = useState<boolean>(false);
  const [emailInvoiceLoader, setEmailInvoiceLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const isDisabledButton = true;
  // const [printPLLoader, setPrintPLLoader] = useState(false);
  const [markAsCompleteLoader, setMarkAsCompleteLoader] = useState(false);
  const [sendAcknowledgementLoader, setSendAcknowledgementLoader] = useState(false);
  const [shippingAmount, SetShippingAmount] = useState(0);
  const [odrDetails, setOrderDetails] = useState<OrderDetail>({
    id: 0,
    orderFormId: 0,
    discount: 0,
    goodsTaxRate: 0,
    isInvoiced: false,
    notes: '',
    deposit: 0,
    orderNotes: '',
    userDetails: {
      id: 0,
      shippingWeek: 0,
      shippingEmail: '',
      shippingAddressName: '',
      shippingAddressBusiness: '',
      shippingAddressPhone: '',
      shippingAddressStreetAddress: '',
      shippingAddressAdddressLine2: '',
      shippingAddressCity: '',
      shippingAddressPostalCode: '',
      shippingAddressProvince: '',
      shippingAmount: 0,
      shippingLabelPrintedAt: '',
      shippedAt: '',
      trackingId: '',
      trackingWebsite: '',
      userNotes: '',
    },
    preDiscAmt: 0,
    credits: 0,
    specialDiscount: 0,
    paymentStatus: '',
    plPrintedAt: '',
    plPrintedCount: 0,
    packedAt: '',
    primaryOrder: 0,
    showReminderButton: false,
    amountPaid: 0,
    fallOrders: [],
    isCancelled: false,
    orderNumber: '',
    revisedOrder: false,
    allowHeated: false,
    products: [
      {
        id: 0,
        name: '',
        size: '',
        type: '',
        subType: '',
        tier1Price: 0,
        tier2Price: 0,
        tier3Price: 0,
        totalStock: 0,
        totalOrdered: 0,
        scrap: 0,
        bufferStock: 0,
        totalScrap: 0,
        imageUrl: '',
        notes: '',
        quantity: 0,
        productId: 0,
        selectedQuantity: 0,
        totalPrice: 0,
        pricePerPiece: 0,
        orderedQuantity: 0,
        productLimit: 0,
      },
    ],
    paymentHistory: [],
    invoiceDetails: {
      subTotal: 0,
      creditAmount: 0,
      discount: 0,
      discountAmount: 0,
      specialDiscountAmount: 0,
      shippingAmount: 0,
      preTaxAmount: 0,
      tax: {
        gst: {
          taxType: '',
          rate: 0,
          amount: 0,
        },
        pstqst: {
          taxType: '',
          rate: 0,
          amount: 0,
        },
        hst: {
          taxType: '',
          rate: 0,
          amount: 0,
        },
        totalTax: {
          rate: 0,
          amount: 0,
        },
      },
      taxAmount: 0,
      grandTotal: 0,
      status: '',
      deposit: 0,
      depositAmount: 0,
      remainingAmount: 0,
      dueDepositAmount: 0,
    },
    shippingDetails: {
      id: 0,
      shippingWeek: 0,
      shippingEmail: '',
      shippingAddressName: '',
      shippingAddressBusiness: '',
      shippingAddressPhone: '',
      shippingAddressStreetAddress: '',
      shippingAddressAdddressLine2: '',
      shippingAddressCity: '',
      shippingAddressPostalCode: '',
      shippingAddressProvince: '',
      shippingAmount: 0,
      shippingLabelPrintedAt: '',
      shippedAt: '',
      trackingId: '',
      trackingWebsite: '',
      userNotes: '',
    },
  });
  //const [selectedFilters, setSelectedFilters] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 3000 
  });
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string }>(() => {
    //const storedFilters = localStorage.getItem('selectedFilters');
    const queryParams: any = {};
    const queryString = window.location.search.substring(1);

    queryString.split('&').forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key && value) {
        queryParams[key] = key == 'searchText' ? value : Number(decodeURIComponent(value));
      }
    });
    return queryParams ? queryParams : {};
  });

  const navigate = useNavigate();

  const filterData = [
    { label: 'Ship Week', type: 'shipweek', options: shippingWeeks, value: '' },
    { label: 'Shipping Year', type: 'shippingyear', options: shippingYear, value: '' },
    { label: 'Product Type', type: 'productLine', options: productTypes, value: '' },
    { label: 'PL Printed', type: 'plPrinted', options: PLPrintedStatus, value: '' },
    { label: 'Packed', type: 'packed', options: packedStatus, value: '' },
    { label: 'Ship Label', type: 'shipLabel', options: shipLabel, value: '' },
    { label: 'Shipped', type: 'shipped', options: shippedStatus, value: '' },
    { label: 'Invoiced', type: 'isInvoiced', options: invoiceStatus, value: '' },
    { label: 'Payment Status', type: 'payment', options: paymentStatus, value: '' },
  ];

  const columns: ColumnsType<AdminOrders> = [
    {
      title: 'Order No',
      dataIndex: 'orderNumber',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Ship Week',
      dataIndex: 'shipweek',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Product Type',
      dataIndex: 'productType',
      width: '15%',
      align: 'center',
    },
    {
      title: 'Business Name',
      dataIndex: 'businessName',
      width: '25%',
      align: 'center',
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      width: '25%',
      align: 'center',
    },
    {
      title: 'Payment Reminder',
      dataIndex: 'paymentReminder',
      width: '10%',
      align: 'center',
    },
    {
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      align: 'center',
      render: (value) => renderSwitch(value),
    },
  ];

  useEffect(() => {
    getShippingWeeks();
    getAllProductTypes();
    if (!selectedFilters) {
      getOrdersList();
    }
  }, []);

  useEffect(()=>{
    if(selectedRows[0]) setEnableBulkEmail(true);
    else setEnableBulkEmail(false);
  },[selectedRows]);

  useEffect(() => {
    handleFilterChange('currentPage',{value: pagination.current});
    const queryParams: any = {};
    const queryString = window.location.search.substring(1);
    queryString.split('&').forEach((pair) => {

      const [key, value] = pair.split('=');
      if (key && value) {
        queryParams[key] = decodeURIComponent(value);
      }
    });
    getOrdersWithFilters(queryParams);
  }, [location.search]);



  const renderSwitch = (paymentStatus: string) => {
    switch (paymentStatus) {
      case 'CANCELLED':
        return (
          <span>
            {
              <Tag color='#42434b' style={{ color: 'white' }}>
                {paymentStatus}
              </Tag>
            }
          </span>
        );
        break;
      case 'UNPAID':
        return <span>{<Tag color='#BA704F'>{paymentStatus}</Tag>}</span>;
        break;
      case 'PAID':
        return <span>{<Tag color='#7A9D54'>{paymentStatus}</Tag>}</span>;
        break;
      case 'OVERPAID':
        return <span>{<Tag color='#75C2F6'>{paymentStatus}</Tag>}</span>;
        break;
      case 'PARTIAL':
        return (
          <span>
            {
              <Tag color='#FDFFAE' style={{ color: 'black' }}>
                {paymentStatus}
              </Tag>
            }
          </span>
        );
        break;
      case 'DEPOSIT PAID':
        return <span>{<Tag color='gold'>{paymentStatus}</Tag>}</span>;
        break;
      case 'NONE':
        return <span>{<Tag color='green'>{paymentStatus}</Tag>}</span>;
        break;
      default:
        return <span></span>;
    }
  };
  // const handleBulkAcknowledgement = async () => {
  //   const odrs = [
  //     {'id': 1587}, {'id': 1592}, {'id': 1593}, {'id': 1597}, {'id': 1599}, {'id': 1601}, 
  //     {'id': 1603}, {'id': 1604}, {'id': 1606}, {'id': 1607}, {'id': 1608}, {'id': 1609}, 
  //     {'id': 1610}, {'id': 1611}, {'id': 1613}, {'id': 1616}, {'id': 1617}, {'id': 1619}, 
  //     {'id': 1621}, {'id': 1623}, {'id': 1624}, {'id': 1625}, {'id': 1627}, {'id': 1628}, 
  //     {'id': 1629}, {'id': 1630}, {'id': 1631}, {'id': 1632}, {'id': 1633}, {'id': 1634}, 
  //     {'id': 1635}, {'id': 1636}, {'id': 1638}, {'id': 1639}, {'id': 1640}, {'id': 1642}, 
  //     {'id': 1643}, {'id': 1644}, {'id': 1646}, {'id': 1647}, {'id': 1648}, {'id': 1649}, 
  //     {'id': 1651}, {'id': 1652}, {'id': 1653}, {'id': 1654}, {'id': 1655}, {'id': 1656}, 
  //     {'id': 1657}, {'id': 1658}, {'id': 1659}, {'id': 1660}, {'id': 1661}, {'id': 1663}, 
  //     {'id': 1664}, {'id': 1665}, {'id': 1666}, {'id': 1668}, {'id': 1669}, {'id': 1670}, 
  //     {'id': 1673}, {'id': 1674}, {'id': 1675}, {'id': 1677}, {'id': 1678}, {'id': 1679}, 
  //     {'id': 1680}, {'id': 1682}, {'id': 1684}, {'id': 1685}, {'id': 1686}, {'id': 1687}, 
  //     {'id': 1688}, {'id': 1689}, {'id': 1690}, {'id': 1692}, {'id': 1693}, {'id': 1694}, 
  //     {'id': 1695}, {'id': 1696}, {'id': 1697}, {'id': 1698}, {'id': 1699}, {'id': 1700}, 
  //     {'id': 1701}, {'id': 1703}, {'id': 1704}, {'id': 1705}, {'id': 1706}, {'id': 1707}, 
  //     {'id': 1708}, {'id': 1709}, {'id': 1710}, {'id': 1711}, {'id': 1712}, {'id': 1713}, 
  //     {'id': 1714}, {'id': 1715}, {'id': 1716}, {'id': 1717}, {'id': 1718}, {'id': 1719}, 
  //     {'id': 1720}, {'id': 1721}, {'id': 1722}, {'id': 1723}, {'id': 1724}, {'id': 1725}, 
  //     {'id': 1726}, {'id': 1727}, {'id': 1728}, {'id': 1729}, {'id': 1730}, {'id': 1731}, 
  //     {'id': 1732}, {'id': 1733}, {'id': 1734}, {'id': 1735}, {'id': 1736}, {'id': 1737}, 
  //     {'id': 1738}, {'id': 1740}, {'id': 1741}, {'id': 1743}, {'id': 1744}, {'id': 1745}, 
  //     {'id': 1746}, {'id': 1747}, {'id': 1748}, {'id': 1749}, {'id': 1750}, {'id': 1751}, 
  //     {'id': 1752}, {'id': 1754}, {'id': 1756}, {'id': 1758}, {'id': 1759}, {'id': 1761}, 
  //     {'id': 1762}, {'id': 1768}, {'id': 1769}, {'id': 1771}, {'id': 1772}, {'id': 1773}, 
  //     {'id': 1774}, {'id': 1776}, {'id': 1784}, {'id': 1785}, {'id': 1789}, {'id': 1790}, 
  //     {'id': 1792}, {'id': 1794}, {'id': 1795}, {'id': 1796}, {'id': 1797}, {'id': 1799}, 
  //     {'id': 1802}, {'id': 1805}, {'id': 1808}, {'id': 1814}, {'id': 1826}, {'id': 1831}, 
  //     {'id': 1834}, {'id': 1842}, {'id': 1844}, {'id': 1854}, {'id': 1866}, {'id': 1878}, 
  //     {'id': 1881}, {'id': 1889}, {'id': 1893}, {'id': 1899}, {'id': 1900}, {'id': 1907}, 
  //     {'id': 1917}, {'id': 1918}, {'id': 1921}, {'id': 1927}, {'id': 1929}, {'id': 1932}, 
  //     {'id': 1933}, {'id': 1936}, {'id': 1938}, {'id': 1942}, {'id': 1943}, {'id': 1944}, 
  //     {'id': 1945}, {'id': 1947}, {'id': 1948}, {'id': 1951}, {'id': 1952}, {'id': 1953}, 
  //     {'id': 1954}, {'id': 1956}, {'id': 1959}, {'id': 1962}, {'id': 1967}, {'id': 1973}, 
  //     {'id': 1976}, {'id': 1980}, {'id': 1987}, {'id': 1996}, {'id': 2001}, {'id': 2003}, 
  //     {'id': 2004}, {'id': 2005}, {'id': 2010}, {'id': 2013}, {'id': 2015}, {'id': 2017}, 
  //     {'id': 2018}, {'id': 2019}, {'id': 2022}, {'id': 2023}, {'id': 2024}, {'id': 2036}, 
  //     {'id': 2050}, {'id': 2051}, {'id': 2055}, {'id': 2057}, {'id': 2060}, {'id': 2066}, 
  //     {'id': 2067}, {'id': 2072}, {'id': 2074}, {'id': 2077}, {'id': 2079}, {'id': 2081}, 
  //     {'id': 2082}, {'id': 2085}, {'id': 2086}, {'id': 2087}, {'id': 2088}, {'id': 2091}, 
  //     {'id': 2095}, {'id': 2096}, {'id': 2097}, {'id': 2099}, {'id': 2101}, {'id': 2103}, 
  //     {'id': 2113}, {'id': 2115}, {'id': 2116}, {'id': 2119}, {'id': 2121}, {'id': 2122}, 
  //     {'id': 2123}, {'id': 2125}, {'id': 2127}, {'id': 2129}, {'id': 2130}, {'id': 2131}, 
  //     {'id': 2134}, {'id': 2137}, {'id': 2140}, {'id': 2143}, {'id': 2144}, {'id': 2149}, 
  //     {'id': 2150}, {'id': 2154}, {'id': 2160}, {'id': 2166}, {'id': 2169}, {'id': 2173}, 
  //     {'id': 2174}, {'id': 2180}, {'id': 2188}, {'id': 2190}, {'id': 2196}, {'id': 2200}, 
  //     {'id': 2216}, {'id': 2235}, {'id': 2240}, {'id': 2244}, {'id': 2296}, {'id': 2298}, 
  //     {'id': 2322}, {'id': 2325}, {'id': 2327}, {'id': 2402}, {'id': 2404}, {'id': 2405}, 
  //     {'id': 2410}, {'id': 2422}, {'id': 2427}, {'id': 2439}, {'id': 2446}, {'id': 2450}
  //   ];
  //   for (let i=0; i<odrs.length; i++){
  //     await api
  //     .get(sendAcknowledgement + '/' + odrs[i].id)
  //     .then((res: any) => {
  //       console.log('-- acknowledgement sent to -- ', odrs[i].id);
  //     })
  //     .catch((error: any) => {
  //       message.error(error?.response?.data?.message);
  //     });
  //   }

  // };

  const handleBulkAcknowledgement = async () => {
    for (let i=0; i<selectedRows.length; i++){
      await api
      .get(sendAcknowledgement + '/' + selectedRows[i].id)
      .then((res: any) => {
        setSendAcknowledgementLoader(false);
        // message.success(res.message);
      })
      .catch((error: any) => {
        message.error('Bulk Acknowledgement Failed');
      });
    }
  };

  const handleBulkInvoices = async () => {
    const notInvoiced =[];
    for(let i=0; i<selectedRows.length; i++) {
      if(selectedRows[i].isInvoiced == false) {
        notInvoiced.push(selectedRows[i]);
        continue;  
      }
      setEmailInvoiceLoader(true);
      api
        .get(`${emailInvoice}/${selectedRows[i].id}`)
        .then(() => {
          setEmailInvoiceLoader(false);
          message.success('Invoice mailed successfully');
        })
        .catch((error: any) => {
          setEmailInvoiceLoader(false);
          console.log(error);
          message.success('Unable to mail invoice');
        });
    }
    if(notInvoiced.length != 0){
      let template = 'Unable to mail invoices for following Order ID: ';
      notInvoiced.forEach(( ele )=> {
        template+=`${ele.orderNumber}, `;
      });
      message.error(template);
    }
  };





  const getShippingWeeks = () => {
    const weeksData = [];
    for (let i = 1; i <= 50; i++) {
      weeksData.push({ id: i, value: `Week ${i}` });
    }
    const shipWeeks = JSON.parse(JSON.stringify(weeksData, null, 2));
    setShippingWeeks(shipWeeks);
  };

  const getAllProductTypes = async () => {
    setLoading(true);
    await api
      .get(getAllProductLines)
      .then((result: any) => {
        const response = result;
        const productTypesList = response.map((object: any) => ({
          id: object.id,
          value: object.name,
        }));
        setProductTypes(productTypesList);
        setLoading(false);
      })
      .catch((error: any) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const getOrdersList = async () => {
    setLoading(true);
    await api
      .post<never, AdminOrders[]>(orderList, order)
      .then((result: AdminOrders[]) => {
        setOrderData(result);
        setLoading(false);
      })
      .catch((error:any) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const getOrdersWithFilters = async (payload: any) => {
    setLoading(true);
    await api
      .post<never, AdminOrders[]>(orderList, payload)
      .then(async (result: AdminOrders[]) => {
        if('shippingyear' in payload) {
          const data = await filterWithYear(result, payload);
          setOrderData(data);
        } else {
          setOrderData(result);
        }
        setLoading(false);
      })
      .catch((error:any) => {
        message.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const filterWithYear = ( result: any, payload: any) => {
    const data = result.filter((ele:any) => {
      let shippingYear = ele.shipweek.toString();
      shippingYear = shippingYear.split(',')[1].trim();
      return shippingYear === payload.shippingyear.toString();
    });
    return data;
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRows(selectedRows);
      // const selectedQuantityTotal = selectedRows.reduce((acc, item) => acc + item.quantity, 0);
      // setQuantityTotal(selectedQuantityTotal);
    },
    getCheckboxProps: (record: any) => ({
      // disabled: record.id === recordId, // Column configuration not to be checked
      // name: record.name,
    }),
  };
  
  const handleInputChange = (event: any) => {
    if (event) {
      if (event.target.value != '') {
        addFilterToQueryParams('searchText', event.target.value);
      } else {
        removeFilterFromQueryParams('searchText');
      }

      setSelectedFilters((prevFilters: any) => ({
        ...prevFilters,
        ['searchText']: event.target.value,
      }));
    }
  };
  
  useEffect( () => {
    if(odrDetails.id != 0) printPL();
  }, [odrDetails]);

  useEffect(() => {
    const queryParams: any = {};
    const queryString = window.location.search.substring(1);

    queryString.split('&').forEach((pair) => {
      const [key, value] = pair.split('=');
      if (key && value) {
        queryParams[key] = decodeURIComponent(value);
      }
    });
    getOrdersWithFilters(queryParams);
  }, [location.search]);

  const printPL = () => {
    console.log('---printing pick list function ---',odrDetails);
    // setTimeout (() => {
      if (!odrDetails || !odrDetails.products) {
        console.error('Order details are missing or incomplete.');
        return;
      }
      const mywindow = window.open('', 'PRINT', 'height=1200,width=1200');
      const getPickListString = ReactDOMServer.renderToString(
        <PickList
          productDetails={odrDetails.products}
          shippingDetails={odrDetails.shippingDetails}
          deliveryNotes={odrDetails.notes}
          fallOrders={odrDetails?.fallOrders}
          isHeated={odrDetails?.allowHeated}
        />,
      );

      if (mywindow) {
        mywindow.document.write(
          '<html> <head><link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet"><style>@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");body,select,option {font-family: "Montserrat", sans-serif;}table {width: 90%;margin-left: 5%;border-collapse: collapse;}th,td {padding: 0.5%;text-align: center;border: 1px solid #CCC;}#header {padding-top: 2%;padding-bottom: 2%;margin-bottom: 1.5%;/* background:#f7ece2; */}#titlewrapper {padding-top: 1%;padding-bottom: 1%;margin-left: 25%;width: 50%;text-align: center;border: 2px solid black;}#title {font-weight: 300;font-size: 1.3em;}#date {font-weight: 300;font-size: 1em;}.align-left {text-align: left;}.align-right {text-align: right;} .width-50 {width: 50%;} .fall-orders{width:90%;margin-left:5%;text-align:center;} </style><title>Pick List</title></head>',
        );
        mywindow.document.write(getPickListString);

        mywindow.document.write(
          '<script>var today = new Date();var year = today.getFullYear();var month = today.getMonth();month = month + 1;var day = today.getDate();var newdate = day + "-" + month + "-" + year; </script> </html>',
        );

        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        // mywindow.close();
      }
    
  };
  const markAsComplete = () => {
    for (let i=0; i<selectedRows.length; i++){
      const payload = { isComplete: true, shippingId: selectedRows[i].shippingId }; //shipping.id 
      setMarkAsCompleteLoader(true);
      api
        .put<never, any>(status + `/${selectedRows[i].id}`, payload) // id
        .then((res: any) => {
          if (res) {
            setMarkAsCompleteLoader(false);
            // message.success(res.message);
            // getOrderDetails(selectedRows[i].id);
          }
        })
        .catch((error: any) => {
          setMarkAsCompleteLoader(false);
          message.error(error?.response?.data?.message);
        });
    }
  };
  const getOrderDetails = async (orderId : any) => {
    setLoading(true);
    await api.get(orderDetails + '/' + orderId).then((res: any) => {
      setOrderDetails(res);
      // setTimeout(()=> {
      //   printPL();
      // },500);
      SetShippingAmount(res.shippingDetails.shippingAmount);
      setLoading(false);
    });
  };
  
  const addFilterToQueryParams = (type: any, value: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(type, value);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  const removeFilterFromQueryParams = (type: any) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(type);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  // useEffect(() => {
  //   localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
  //   getOrdersWithFilters(selectedFilters);
  // }, [selectedFilters]);

  const handleFilterChange = (type: any, event: any) => {
    const value = event ? Number(event.value) : undefined;

    // Add or remove the filter from query parameters based on the filter type
    if (value !== undefined) {
      addFilterToQueryParams(type, value);
    } else {
      removeFilterFromQueryParams(type);
    }

    setSelectedFilters((prevFilters: any) => ({
      ...prevFilters,
      [type]: event ? Number(event.value) : undefined,
    }));
  };

  const debouncedHandleInputChange = debounce(handleInputChange, 1000);

  const rowProps = (record: any) => {
    return {
      onClick: () => navigate(`/order/${record.id}`),
    };
  };

  const handleBulkReminder = () => {
    setVisible(true);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const options: any = Array.from({ length: 50 }, (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  }));

  const handleShipWeekChange = (value: number) => {
    setSelectedShipWeek(value);
  };

  const handleProductTypeChange = (value: string) => {
    setSelectedProductType(value);
  };

  const handlePayment = (value: number) => {
    setSelectedPayment(value);
  };

  const paginationChange = (page: any) => {
    setPagination(() => {
      return {
        pageSize: page.pageSize,
        current: page.current,
        total: 3000
      };
    });
  };

  useEffect(() => {
    handleFilterChange('currentPage',{value:  pagination.current});
  }, [pagination]);

  const handleShipped = (value: number) => {
    setSelectedShipped(value);
  };

  const sendReminder = () => {
    setLoaer(true);
    form
      .validateFields()
      .then((values: any) => {
        api
          .put(bulkReminder, values)
          .then((res: any) => {
            message.success(res.message);
            setLoaer(false);
            form.resetFields();
          })
          .catch((error) => {
            message.error(error.data.message);
            setLoaer(false);
          });
      })
      .catch((error: any) => {
        setLoaer(false);
      });
  };

  return (
    <div className='order-main px-2'>
      <h3 className='title-grey'>Orders</h3>
      <Card className='mb-4 filter-row'>
        <span className='search-row d-flex justify-content-center p-2'>
          <Search
            placeholder='Search by Contact name / Business name'
            onChange={debouncedHandleInputChange}
            allowClear
            defaultValue={selectedFilters['searchText']}
            size='large'
            style={{ width: 600 }}
          />
        </span>
        <span className='search-row d-flex justify-content-center p-2'>
          <Button
              style={{margin: '1%'}}
              type='primary'
              onClick={handleBulkAcknowledgement}
              disabled={!enableBulkEmail}
              loading={sendAcknowledgementLoader || loading}
            >
            Email Bulk Acknowledgements
          </Button> 
          <Button 
              style={{margin: '1%'}}
              type='primary'
              onClick={markAsComplete}
              disabled={!enableBulkEmail}
              loading={markAsCompleteLoader || loading}
            >
            Mark as Complete
          </Button>
        </span>

        <span className='search-row d-flex justify-content-end p-2'>
          <Button
            className='d-none'
            type='primary'
            icon={<HistoryOutlined />}
            onClick={handleBulkReminder}
          >
            Bulk Reminder
          </Button>
          <Modal
            open={visible}
            title={'Send a bulk Reminder'}
            onCancel={onCancel}
            onOk={sendReminder}
            destroyOnClose
            footer={[
              <Button key='no' onClick={onCancel}>
                Cancel
              </Button>,
              <Button key='ok' type='primary' onClick={sendReminder} loading={loader}>
                Send Reminder
              </Button>,
            ]}
          >
            <Form form={form} layout='vertical' onFinish={sendReminder}>
              <div className='d-flex justify-content-center gap-4'>
                <Form.Item label='Ship Week' name='shipweek' className='w-100'>
                  <Select
                    placeholder='Select Ship Week'
                    onChange={handleShipWeekChange}
                    value={selectedShipWeek}
                  >
                    {options.map((options: any) => (
                      <Select.Option key={options.value} value={options.value}>
                        {options.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label='Product Type' name='productLine' className='w-100'>
                  <Select
                    placeholder='Select Product Type'
                    onChange={handleProductTypeChange}
                    value={selectedProductType}
                  >
                    {productTypes.map((options: any) => (
                      <Select.Option key={options.value} value={options.value}>
                        {options.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='d-flex justify-content-center gap-4'>
                <Form.Item label='Shipped' name='shipped' className='w-100'>
                  <Select
                    placeholder='Select Shipped Status'
                    value={selectedShipped}
                    onChange={handleShipped}
                  >
                    <Select.Option value={1}>Not Shipped</Select.Option>
                    <Select.Option value={2}>Shipped</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[{ required: true, message: 'Please Select Payment Type' }]}
                  label='Payment'
                  name='payment'
                  className='w-100'
                >
                  <Select
                    placeholder='Select Payment Type'
                    value={selectedPayment}
                    onChange={handlePayment}
                  >
                    <Select.Option value={1}>Deposit Reminder</Select.Option>
                    <Select.Option value={2}>Payment Reminder</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Form>
          </Modal>
        </span>

        <hr className='m-2' />
        <div className='d-flex flex-wrap gap-2 py-2' style={{ justifyContent: 'space-between' }}>
          {filterData.map((item, index) => (
            <div key={index} className='dropdown-container d-flex flex-column fw-600'>
              <span className='pb-2'>
                <label>{item.label}</label>
              </span>
              <Select
                placeholder={'Select ' + item.label}
                allowClear
                //options={item.options}
                options={item.options.map((option) => ({ label: option.value, value: option.id }))}
                style={{ width: '200px' }}
                value={selectedFilters[item.type]}
                onChange={(value, event) => handleFilterChange(item.type, event)}
              ></Select>
            </div>
          ))}
        </div>
        <hr />
        <Table
          bordered
          columns={columns}
          loading={loading}
          dataSource={order}
          rowKey='id'
          onRow={rowProps}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          onChange = {paginationChange}
          pagination={{ ...pagination, showSizeChanger: false }}
        />
      </Card>
    </div>
  );
}

export default OrderTable;
